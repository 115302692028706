import React from 'react';
import QRCode from 'react-qr-code';
import { string } from 'prop-types';

import { Box, Text, Center } from '@chakra-ui/react';

function QRCard({ name, code, desc }) {
  return (
    <Box>
      <Box>
        <Center>
          <QRCode value={code} size={140} />
        </Center>
      </Box>
      <Box marginTop="8px">
        <Text align="center" fontSize="xs">{name}</Text>
        <Text align="center" fontSize="xs">{`(${desc})`}</Text>
      </Box>
    </Box>
  );
}

QRCard.propTypes = {
  name: string.isRequired,
  desc: string.isRequired,
  code: string.isRequired,
};

export default React.memo(QRCard);
